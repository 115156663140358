/**
 * a link 元件
 */

import styled from "styled-components";

const NormalLink = styled.a<any>`
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  color: black;
  cursor: pointer;
`;
export default NormalLink;
