declare const API_URL: string

import axios from 'axios'
import { CheckAPIURL } from '../Check'

/**
 * 對象：任意人
 * 動作：查詢手續費費率
 */

export const getPaymentRequestFeeRatesFromGuess = async () => {
  let result
  try {
    CheckAPIURL()
    const res = await axios.post(API_URL, {
      query: `
          query {
            getPaymentRequestFeeRatesFromGuess {   
               payload{
                id
                rate
                year
                month
                started_at
                end_at
              }
              errors
              status
              message
            }
          }`,
    })
    result = res.data.data.getPaymentRequestFeeRatesFromGuess
    const errors = res.data.data.getPaymentRequestFeeRatesFromGuess.errors
    if (errors) {
      throw errors
    }
  } catch (err) {
    console.log('Error of getPaymentRequestFeeRatesFromGuess', err)
  }
  return result
}
