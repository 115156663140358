import {
  Banner,
  Button,
  CheckBox,
  Modal,
  Snackbar,
  SWAPSpace,
  Tab,
  TabPanel,
  Tabs,
  TextField,
  Typography,
} from '@yosgo/swap-ui'
import React, { memo, useEffect, useState } from 'react'

import MailOutlineIcon from '@material-ui/icons/MailOutline'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import {
  Box,
  Fade,
  IconButton,
  useMediaQuery,
  makeStyles,
  useTheme,
  Theme,
} from '@material-ui/core'
import {
  GetMember,
  Login,
  RegisterMember,
  RequestResetPassword,
} from '../../utils/AppRequest'
import { ParseAccountToken, WriteAccountToken } from '../../utils/HandleToken'
import PathAndTitle from '../../utils/PathAndTitle'
import styled from 'styled-components'
import { WindowExist } from '../../utils/HandleMode'
import NormalLink from './NormalLink'
import { PathOr } from '../../utils/FunctionsBox'
import QS from '../../utils/QS'
import { FreshChatSaveRestoreId } from './FreshChat'
import jwt from 'jsonwebtoken'
import { getSponsorOrdersFromSOHO } from '../../utils/appRequest/price/plan'

const handleEmailValidation = (email: string) => {
  let re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

const Link = styled.a<any>`
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  color: black;
`
// Style
interface StyleProps {
  isIOSMessage?: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  textfield_password: {
    '& input': {
      '&::-ms-reveal ': {
        display: 'none',
      },
    },
  },
  scrollbar: {
    '&::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      width: 5,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.black.black600,
      borderRadius: 100,
    },
    '&::-webkit-scrollbar-track-piece:start': {
      marginTop: 12,
    },
    '&::-webkit-scrollbar-track-piece:end': {
      marginBottom: 12,
    },
  },
  googleButton: (props) => ({
    opacity: props.isIOSMessage ? 0.4 : 1,
    textAlign: 'center',
    width: '100%',
    height: 48,
    backgroundColor: 'white',
    cursor: props.isIOSMessage ? 'not-allowed' : 'pointer',
    padding: '14px 24px',
    position: 'relative',
    border: '1px solid #909090',
    boxSizing: 'border-box',
    borderRadius: 8,
    boxShadow: 'none',
    color: 'black',
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '18px',
    fontFamily: 'unset',
    '&:hover': {
      backgroundColor: props.isIOSMessage ? 'transparent' : '#F6F6F6',
    },
  }),
  facebookButton: {
    textAlign: 'center',
    width: '100%',
    height: 48,
    marginBottom: 12,
    backgroundColor: 'white',
    cursor: 'pointer',
    padding: '14px 24px',
    position: 'relative',
    border: '1px solid #909090',
    boxSizing: 'border-box',
    borderRadius: 8,
    fontSize: 16,
    lineHeight: '18px',
    fontWeight: 700,
    fontFamily: 'unset',
    '&:hover': {
      backgroundColor: '#F6F6F6',
    },
  },
}))

interface AuthModalProps {
  open: boolean
  onClose: () => void
  type: string
  isFromPricing?: boolean
}

const AuthModal = ({ open, onClose, type, isFromPricing }: AuthModalProps) => {
  const [value, setValue] = useState(0)
  const [isOpenSnackbar, setIsOpenSnackbar] = useState(false)
  const [resetPassword, setResetPassword] = useState(false)
  const [email, setEmail] = useState('')
  const [tokenEmail, setTokenEmail] = useState('')
  const [clienId, setClienId] = useState('')
  const [redirectUri, setRedirectUri] = useState('')
  const [thirdPartyLoginError, setThirdPartyLoginError] = useState(false)
  const [thirdPartyResgisterError, setThirdPartyResgisterError] =
    useState(false)
  const [thirdPartyAPIError, setThirdPartyAPIError] = useState(false)
  const [thirdPartyAPIErrorCode, setThirdPartyAPIErrorCode] = useState('')
  const [isIOSMessage, setIsIOSMessage] = useState(false)
  const [matchSM, setMatchSM] = useState(false)

  useEffect(() => {
    setValue(type === 'login' ? 0 : 1)
  }, [type])

  useEffect(() => {
    // 若已登入，自動填入帳號(Email)
    setTokenEmail(PathOr('', ['email'], ParseAccountToken()))

    // 判斷環境 (staging ||　production)
    const isStaging =
      window.location.origin.indexOf('staging.swap.work') >= 0 ||
      window.location.origin.indexOf('localhost') >= 0

    // google oauth clienID (develop or production)
    setClienId(
      !isStaging
        ? '684082989061-q2rjgagfqkdvcihjkvgebd9r1u03nq4a.apps.googleusercontent.com'
        : '684082989061-d4ru5m8npkb4hg95i3r2lliro609hkj6.apps.googleusercontent.com'
    )

    // redirect-uri(develop or production)
    setRedirectUri(
      !isStaging
        ? 'https://swap.work/loadingpage'
        : 'https://staging.swap.work/loadingpage'
    )

    // 判斷第三方登入/註冊 錯誤狀態
    const state: any = QS('state', window.location.href)
    if (state === 'login') {
      setThirdPartyLoginError(true)
    }
    if (state === 'register') {
      setThirdPartyResgisterError(true)
    }
    // 判斷錯誤訊息是否是來自 第三方API
    const error: any = QS('error', window.location.href)
    const status: any = QS('status', window.location.href)

    if (
      error !== 'Unauthorization' &&
      error !== 'member not found' &&
      error !== '重複註冊'
    ) {
      setThirdPartyAPIError(true)
      setThirdPartyAPIErrorCode(status)
    }

    // 判斷是否為 IOS Message in-app 瀏覽器
    const userAgent = navigator.userAgent
    const isFB = userAgent.indexOf('FBAV') > -1
    const isIOS = /iPhone|iPad|iPod/i.test(userAgent)
    if (isFB && isIOS) {
      setIsIOSMessage(true)
    } else {
      setIsIOSMessage(false)
    }
  }, [])

  // 滑動關閉Snackbar Event
  WindowExist()
    ? (window.onscroll = function handleScroll() {
        if (isOpenSnackbar) {
          setIsOpenSnackbar(false)
        }
      })
    : null

  const match_SM = useMediaQuery('(min-width:576px)')

  useEffect(() => {
    setMatchSM(match_SM)
  }, [match_SM])

  return (
    <>
      <Modal
        bodyStyle={{ overflowY: 'unset' }}
        bodyPadding={matchSM ? '0 0' : '0 0'}
        headpadding={resetPassword ? undefined : '0px 24px'}
        open={open}
        maxWidth={480}
        mobile={!matchSM}
        onClose={() => {
          onClose()
          setResetPassword(false)
        }}
        title={
          !resetPassword ? (
            <Tabs value={value}>
              <Tab
                animation
                label="登入帳號"
                width={72}
                height={64}
                margin="0px 12px 0px 0px"
                fontSize={18}
                onClick={() => {
                  setValue(0)
                  document
                    .getElementById('top')
                    ?.scrollIntoView({ behavior: 'smooth' })
                }}
              />
              <Tab
                animation
                label="註冊帳號"
                width={72}
                height={64}
                margin={0}
                fontSize={18}
                onClick={() => {
                  setValue(1)
                  document
                    .getElementById('top')
                    ?.scrollIntoView({ behavior: 'smooth' })
                }}
              />
            </Tabs>
          ) : (
            '找回你的密碼'
          )
        }
      >
        <div id="top" style={{ position: 'absolute', top: 0 }} />
        {resetPassword ? (
          <ResetPassword
            onClose={onClose}
            setResetPassword={setResetPassword}
            setIsOpenSnackbar={setIsOpenSnackbar}
            email={email}
            setEmail={setEmail}
          />
        ) : (
          <>
            {/* 登入 */}
            <TabPanel value={value} index={0}>
              <LoginBlock
                setResetPassword={setResetPassword}
                tokenEmail={tokenEmail}
                clienId={clienId}
                redirectUri={redirectUri}
                thirdPartyLoginError={thirdPartyLoginError}
                thirdPartyAPIError={thirdPartyAPIError}
                thirdPartyAPIErrorCode={thirdPartyAPIErrorCode}
                isIOSMessage={isIOSMessage}
                matchSM={matchSM}
                isFromPricing={isFromPricing}
              />
            </TabPanel>
            {/* 註冊 */}
            <TabPanel value={value} index={1}>
              <RegisterBlock
                setValue={setValue}
                clienId={clienId}
                redirectUri={redirectUri}
                thirdPartyResgisterError={thirdPartyResgisterError}
                thirdPartyAPIError={thirdPartyAPIError}
                thirdPartyAPIErrorCode={thirdPartyAPIErrorCode}
                isIOSMessage={isIOSMessage}
                matchSM={matchSM}
              />
            </TabPanel>
          </>
        )}
      </Modal>
      <Snackbar
        width="100%"
        open={isOpenSnackbar}
        onClose={() => setIsOpenSnackbar(false)}
        autoHideDuration={5000}
        transitionDirection="up"
        transitionDuration={{ enter: 500, exit: 500 }}
        message={`重置密碼的信件已寄至 ${email}`}
        action={
          <IconButton
            style={{ padding: 0, marginRight: 12 }}
            onClick={() => setIsOpenSnackbar(false)}
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                fill="white"
              />
            </svg>
          </IconButton>
        }
      />
    </>
  )
}

interface LoginBlockProps {
  setResetPassword: (resetPassword: boolean) => void
  tokenEmail: string
  clienId: string
  redirectUri: string
  thirdPartyLoginError: boolean
  thirdPartyAPIError: boolean
  thirdPartyAPIErrorCode: string
  isIOSMessage: boolean
  matchSM: boolean
  isFromPricing?: boolean
}

const LoginBlock = ({
  setResetPassword,
  tokenEmail,
  clienId,
  redirectUri,
  thirdPartyLoginError,
  thirdPartyAPIError,
  thirdPartyAPIErrorCode,
  isIOSMessage,
  matchSM,
  isFromPricing,
}: LoginBlockProps) => {
  const theme = useTheme()
  const [email, setEmail] = useState(tokenEmail)
  const [password, setPassword] = useState('')
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [errorMessages, setErrorMessages] = useState('')
  const [errorBorderFieldFirst, setErrorBorderFieldFirst] = useState(false)
  const [errorBorderFieldSecond, setErrorBorderFieldSecond] = useState(false)
  const [errorTextField, setErrorTextField] = useState(0)
  const [oauthOrigin, setOauthOrigin] = useState('')
  const [clientHeight, setClientHeight] = useState(0)
  const [showPasswordResetSuccess, setShowPasswordResetSuccess] =
    useState(false)
  useEffect(() => {
    const r = QS('reset', window.location.href)
    const t = QS('type', window.location.href)
    if (r === 'success' && t === 'member') {
      setShowPasswordResetSuccess(true)
    }
  }, [])
  const handleThirdPartyAuthLogin = async () => {
    try {
      setLoading(true)
      const loginResult: any = await Login({
        email: email,
        password: password,
        type: 'member',
        oauth_origin: oauthOrigin,
      })
      setLoading(false)
      if (loginResult) {
        if (loginResult.errors === null) {
          ;(async () => {
            /**寫入 token */
            WriteAccountToken(loginResult.payload.token)
            // 設定 Freshchat External Id => 為了產生 Restore id
            const token = jwt.decode(loginResult.payload.token)
            const id = PathOr(undefined, ['id'], token)
            const restore_id = PathOr(undefined, ['fc_restore_id'], token)
            if (isFromPricing) {
              const memberRes: any = await GetMember(`payload{
              member{
                identity
              }
          }`)
              if (memberRes && memberRes.errors === null) {
                if (memberRes.payload.member.identity === 'subscribe') {
                  if (!restore_id) {
                    await FreshChatSaveRestoreId(id).then(
                      () =>
                        /**導向至帳戶首頁*/
                        (window.location.href = '/pricing')
                    )
                  } else {
                    /**導向至帳戶首頁*/
                    window.location.href = '/pricing'
                  }
                } else {
                  const res = await getSponsorOrdersFromSOHO({
                    offset: 0,
                    limit: 1,
                  })
                  if (res && res.errors === null) {
                    if (res.payload.length > 0 && !res.payload[0].is_pay) {
                      if (!restore_id) {
                        await FreshChatSaveRestoreId(id).then(
                          () =>
                            /**導向至帳戶首頁*/
                            (window.location.href =
                              PathAndTitle.member.plan.path)
                        )
                      } else {
                        /**導向至帳戶首頁*/
                        window.location.href = PathAndTitle.member.plan.path
                      }
                    } else {
                      if (!restore_id) {
                        await FreshChatSaveRestoreId(id).then(
                          () =>
                            /**導向至帳戶首頁*/
                            PathAndTitle.member.plan.sponsor.path
                        )
                      } else {
                        /**導向至帳戶首頁*/
                        window.location.href =
                          PathAndTitle.member.plan.sponsor.path
                      }
                    }
                  }
                }
              }
            } else {
              if (!restore_id) {
                await FreshChatSaveRestoreId(id).then(
                  () =>
                    /**導向至帳戶首頁*/
                    (window.location.href = PathAndTitle.member.home.path)
                )
              } else {
                /**導向至帳戶首頁*/
                window.location.href = PathAndTitle.member.home.path
              }
            }
          })()
        } else {
          setOauthOrigin('')
          setErrorMessage('')
          setErrorMessages('')
          setErrorBorderFieldFirst(false)
          setErrorBorderFieldSecond(false)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (oauthOrigin !== '') {
      handleThirdPartyAuthLogin()
    }
  }, [oauthOrigin, email, password])

  const handleLogin = async () => {
    try {
      /**驗證 */
      if (email.length === 0) {
        throw '請輸入帳號'
      }
      if (!handleEmailValidation(email)) {
        throw '帳號請輸入正確的信箱，請確認信箱格式是否正確'
      }
      if (password.length < 8) {
        throw '密碼格式為 8 個字元以上'
      }
      /**登入 */
      setLoading(true)
      const loginResult: any = await Login({
        email: email,
        password: password,
        type: 'member',
        oauth_origin: oauthOrigin,
      })
      setLoading(false)
      if (loginResult && loginResult.errors === null) {
        ;(async () => {
          /**寫入 token */
          WriteAccountToken(loginResult.payload.token)
          // 設定 Freshchat External Id => 為了產生 Restore id
          const token = jwt.decode(loginResult.payload.token)
          const id = PathOr(undefined, ['id'], token)
          const restore_id = PathOr(undefined, ['fc_restore_id'], token)
          if (isFromPricing) {
            const memberRes: any = await GetMember(`payload{
              member{
                identity
              }
          }`)
            if (memberRes && memberRes.errors === null) {
              if (memberRes.payload.member.identity === 'subscribe') {
                if (!restore_id) {
                  await FreshChatSaveRestoreId(id).then(
                    () =>
                      /**導向至帳戶首頁*/
                      (window.location.href = '/pricing')
                  )
                } else {
                  /**導向至帳戶首頁*/
                  window.location.href = '/pricing'
                }
              } else {
                const res = await getSponsorOrdersFromSOHO({
                  offset: 0,
                  limit: 1,
                })
                if (res && res.errors === null) {
                  if (res.payload.length > 0 && !res.payload[0].is_pay) {
                    if (!restore_id) {
                      await FreshChatSaveRestoreId(id).then(
                        () =>
                          /**導向至帳戶首頁*/
                          (window.location.href = PathAndTitle.member.plan.path)
                      )
                    } else {
                      /**導向至帳戶首頁*/
                      window.location.href = PathAndTitle.member.plan.path
                    }
                  } else {
                    if (!restore_id) {
                      await FreshChatSaveRestoreId(id).then(
                        () =>
                          /**導向至帳戶首頁*/
                          (window.location.href =
                            PathAndTitle.member.plan.sponsor.path)
                      )
                    } else {
                      /**導向至帳戶首頁*/
                      window.location.href =
                        PathAndTitle.member.plan.sponsor.path
                    }
                  }
                }
              }
            }
          } else {
            if (!restore_id) {
              await FreshChatSaveRestoreId(id).then(
                () =>
                  /**導向至帳戶首頁*/
                  (window.location.href = PathAndTitle.member.home.path)
              )
            } else {
              /**導向至帳戶首頁*/
              window.location.href = PathAndTitle.member.home.path
            }
          }
        })()
      } else {
        throw '您輸入的帳號或是密碼不正確，請再次確認，或嘗試使用第三方登入'
      }
    } catch (err: any) {
      setErrorMessage(err)
    }
  }
  useEffect(() => {
    if (errorMessage.length !== 0) {
      if (
        errorMessage === '請輸入帳號' ||
        errorMessage === '帳號請輸入正確的信箱，請確認信箱格式是否正確'
      ) {
        setErrorTextField(1)
        setErrorBorderFieldFirst(true)
        setErrorBorderFieldSecond(false)
      } else if (errorMessage === '密碼格式為 8 個字元以上') {
        setErrorTextField(2)
        setErrorBorderFieldSecond(true)
        setErrorBorderFieldFirst(false)
      } else if (
        errorMessage ===
        '您輸入的帳號或是密碼不正確，請再次確認，或嘗試使用第三方登入'
      ) {
        setErrorTextField(2)
        setErrorBorderFieldFirst(true)
        setErrorBorderFieldSecond(true)
      }
      setErrorMessages(errorMessage)
    }
  }, [errorMessage])
  const handleEnterLogin = (event: any) => {
    if (event.keyCode === 13) {
      handleLogin()
    }
  }
  useEffect(() => {
    //Modal maxHeight
    const client_height: number = window.innerHeight
    setClientHeight(client_height - 32 - 64 - 120)
  }, [])

  const styleProps: StyleProps = {}
  const classes = useStyles(styleProps)
  return (
    <>
      <div
        className={classes.scrollbar}
        style={{
          maxHeight: matchSM ? 350 : clientHeight > 350 ? 350 : clientHeight,
          overflowY: 'scroll',
          padding: matchSM ? '24px 24px 0 24px ' : '16px 16px 0 16px',
          minHeight: 333,
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <FacebookLoginButton state="login" redirectUri={redirectUri} />
          <GoogleLoginButton
            clienId={clienId}
            state="login"
            redirectUri={redirectUri}
            isIOSMessage={isIOSMessage}
          />
        </div>
        {isIOSMessage ? (
          <>
            <SWAPSpace size={12} />
            <Banner variant="info">
              iOS系統在messenger的內建瀏覽器中不支援Google服務，如需使用Google帳號繼續，請點擊視窗右下角使用原生瀏覽器開啟。
            </Banner>
          </>
        ) : null}
        {thirdPartyLoginError && errorTextField === 0 ? (
          <Typography
            variant="body2_loose"
            style={{ color: '#e20f0f', marginTop: 8, textAlign: 'center' }}
          >
            {thirdPartyAPIError ? (
              <>
                錯誤代碼 {thirdPartyAPIErrorCode} ，請聯絡透過{' '}
                <NormalLink
                  href="mailto:swap@yosgo.com"
                  target="_blank"
                  style={{ color: '#e20f0f', fontWeight: 700 }}
                >
                  swap@yosgo.com客服
                </NormalLink>
              </>
            ) : (
              '此帳號尚未通過第三方註冊，請先使用第三方註冊，或嘗試使用帳號密碼登入'
            )}
          </Typography>
        ) : null}
        <div
          style={{
            height: 1,
            width: '100%',
            backgroundColor: theme.black.black500,
            margin: '24px 0',
          }}
        />
        {showPasswordResetSuccess ? (
          <>
            <Banner variant="success">
              密碼已經變更成功，請重新進行登入。
            </Banner>
            <SWAPSpace size={24} />
          </>
        ) : null}
        <TextField
          error={errorBorderFieldFirst}
          helperText={
            errorTextField === 1 ? (
              <Fade in={true} timeout={{ enter: 300, exit: 0 }}>
                <Box marginTop="4px">{errorMessages}</Box>
              </Fade>
            ) : null
          }
          fullWidth
          height={56}
          type="email"
          label={email ? '請輸入帳號(Email)' : ''}
          placeholder={!email ? '請輸入帳號(Email)' : ''}
          defaultValue={tokenEmail}
          onChange={(e) => {
            setEmail(e.target.value)
          }}
          onKeyDown={(e) => {
            handleEnterLogin(e)
          }}
          InputProps={{
            startAdornment: (
              <MailOutlineIcon
                style={{
                  width: 20,
                  height: 20,
                  marginRight: 10,
                  color: theme.black.black700,
                }}
              />
            ),
          }}
          style={{ marginBottom: 12 }}
        />
        <TextField
          className={classes.textfield_password}
          error={errorBorderFieldSecond}
          helperText={
            errorTextField === 2 ? (
              <Fade in={true} timeout={{ enter: 300, exit: 0 }}>
                <Box marginTop="4px">{errorMessages}</Box>
              </Fade>
            ) : null
          }
          fullWidth
          height={56}
          type={visible ? 'text' : 'password'}
          label={
            password ? (
              <div style={{ height: 14, overflow: 'hidden' }}>
                密碼(8個字元以上)
              </div>
            ) : (
              ''
            )
          }
          placeholder={!password ? '密碼(8個字元以上)' : ''}
          onChange={(e) => {
            setPassword(e.target.value)
          }}
          onKeyDown={(e) => {
            handleEnterLogin(e)
          }}
          InputProps={{
            startAdornment: (
              <LockOutlinedIcon
                style={{
                  width: 20,
                  height: 20,
                  marginRight: 10,
                  color: theme.black.black700,
                }}
              />
            ),
            endAdornment: (
              <IconButton
                onClick={() => setVisible(!visible)}
                disableRipple
                style={{ width: 24, height: 24 }}
              >
                {visible ? (
                  <Visibility
                    style={{
                      width: 24,
                      height: 24,
                      color: theme.black.black700,
                    }}
                  />
                ) : (
                  <VisibilityOff
                    style={{
                      width: 24,
                      height: 24,
                      color: theme.black.black700,
                    }}
                  />
                )}
              </IconButton>
            ),
          }}
          style={{
            marginBottom: 12,
          }}
        />
      </div>
      {isIOSMessage ||
      thirdPartyLoginError ||
      errorMessages ||
      (!matchSM && clientHeight <= 325) ||
      showPasswordResetSuccess === true ? (
        <div
          style={{
            width: '100%',
            height: 1,
            backgroundColor: '#dadada',
            marginBottom: 16,
          }}
        />
      ) : null}
      <div
        style={{
          padding: matchSM ? '0 24px 24px 24px ' : '0 16px 16px 16px',
        }}
      >
        <Button
          variant="primary"
          loading={loading}
          fullWidth
          onClick={handleLogin}
          style={{ marginBottom: 16 }}
        >
          馬上登入
        </Button>
        <Typography variant="body1" style={{ textAlign: 'center' }}>
          忘記密碼？
          <NormalLink
            onClick={() => setResetPassword(true)}
            style={{ fontWeight: 700 }}
          >
            點這裡找回密碼
          </NormalLink>
        </Typography>
      </div>
    </>
  )
}

interface RegisterBlockProps {
  setValue: (value: number) => void
  clienId: string
  redirectUri: string
  thirdPartyResgisterError: boolean
  thirdPartyAPIError: boolean
  thirdPartyAPIErrorCode: string
  isIOSMessage: boolean
  matchSM: boolean
}

const RegisterBlock = ({
  setValue,
  clienId,
  redirectUri,
  thirdPartyResgisterError,
  thirdPartyAPIError,
  thirdPartyAPIErrorCode,
  isIOSMessage,
  matchSM,
}: RegisterBlockProps) => {
  const theme = useTheme()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [agreement, setAgreement] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [errorMessages, setErrorMessages] = useState('')
  const [errorTextField, setErrorTextField] = useState(0)
  const [loading, setLoading] = useState(false)
  const [oauthOrigin, setOauthOrigin] = useState('')
  const [visible, setVisible] = useState(false)

  const handleThirdPartyAuthRegister = async () => {
    setLoading(true)
    const registerMemberResult: any = await RegisterMember({
      email: email,
      password: password,
      oauth_origin: oauthOrigin,
    })
    setLoading(false)
    if (registerMemberResult) {
      if (registerMemberResult.errors === null) {
        ;(async () => {
          /**寫入 token */
          WriteAccountToken(registerMemberResult.payload.token)
          // 設定 Freshchat External Id => 為了產生 Restore id
          const token = jwt.decode(registerMemberResult.payload.token)
          const id = PathOr(undefined, ['id'], token)
          const restore_id = PathOr(undefined, ['fc_restore_id'], token)
          if (!restore_id) {
            await FreshChatSaveRestoreId(id).then(
              () =>
                /**導向至帳戶首頁*/
                (window.location.href = `${PathAndTitle.member.home.path}/form`)
            )
          } else {
            /**導向至帳戶首頁*/
            window.location.href = `${PathAndTitle.member.home.path}/form`
          }
        })()
      } else {
        setOauthOrigin('')
        setErrorMessage('')
        setErrorMessages('')
        setErrorTextField(0)
      }
    }
  }

  useEffect(() => {
    if (oauthOrigin !== '') {
      handleThirdPartyAuthRegister()
    }
  }, [oauthOrigin, email, password])

  const handleRegister = async () => {
    try {
      /**驗證 */
      if (email.length === 0) {
        throw '請輸入帳號'
      }
      if (!handleEmailValidation(email)) {
        throw '帳號請輸入正確的信箱，請確認信箱格式是否正確'
      }
      if (password.length < 8) {
        throw '密碼格式為 8 個字元以上'
      }
      if (password !== passwordConfirm) {
        throw '請確認密碼欄位中的密碼是否一致'
      }
      if (!agreement) {
        throw '請先同意我們的服務條款'
      }
      // 會員註冊
      setLoading(true)
      const registerMemberResult: any = await RegisterMember({
        email,
        password,
        oauth_origin: oauthOrigin,
      })
      setLoading(false)
      if (registerMemberResult && registerMemberResult.errors === null) {
        ;(async () => {
          /**寫入 token */
          WriteAccountToken(registerMemberResult.payload.token)
          // 設定 Freshchat External Id => 為了產生 Restore id
          const token = jwt.decode(registerMemberResult.payload.token)
          const id = PathOr(undefined, ['id'], token)
          const restore_id = PathOr(undefined, ['fc_restore_id'], token)
          if (!restore_id) {
            await FreshChatSaveRestoreId(id).then(
              () =>
                /**導向至帳戶首頁*/
                (window.location.href = `${PathAndTitle.member.home.path}/form`)
            )
          } else {
            /**導向至帳戶首頁*/
            window.location.href = `${PathAndTitle.member.home.path}/form`
          }
        })()
      } else {
        throw `此信箱已註冊過(${email})`
      }
    } catch (err: any) {
      setErrorMessage(err)
    }
  }
  useEffect(() => {
    if (errorMessage.length !== 0) {
      if (
        errorMessage === '請輸入帳號' ||
        errorMessage === '帳號請輸入正確的信箱，請確認信箱格式是否正確' ||
        errorMessage === `此信箱已註冊過(${email})`
      ) {
        setErrorTextField(1)
      } else if (errorMessage === '密碼格式為 8 個字元以上') {
        setErrorTextField(2)
      } else if (errorMessage === '請確認密碼欄位中的密碼是否一致') {
        setErrorTextField(3)
      } else if (errorMessage === '請先同意我們的服務條款') {
        setErrorTextField(4)
      }
      setErrorMessages(errorMessage)
    }
  }, [errorMessage])

  const handleEnterLogin = (event: any) => {
    if (event.keyCode === 13) {
      handleRegister()
    }
  }

  const styleProps: StyleProps = {}
  const classes = useStyles(styleProps)
  return (
    <Box>
      <div
        className={classes.scrollbar}
        style={{
          maxHeight: `calc(100vh - 198px)`, //扣除 modal header, footer 的高度，當視窗高度不足時，內容變成捲動的。
          overflowY: 'scroll',
          padding: matchSM ? '24px 24px 0 24px ' : '16px 16px 0 16px',
          minHeight: 350,
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <FacebookLoginButton state="register" redirectUri={redirectUri} />
          <GoogleLoginButton
            clienId={clienId}
            state="register"
            redirectUri={redirectUri}
            isIOSMessage={isIOSMessage}
          />
        </div>
        <SWAPSpace size={12} />
        {isIOSMessage ? (
          <Banner variant="info" style={{ marginBottom: 8 }}>
            iOS系統在messenger的內建瀏覽器中不支援Google服務，如需使用Google帳號繼續，請點擊視窗右下角使用原生瀏覽器開啟。
          </Banner>
        ) : null}
        <Typography
          variant="caption2"
          color="black800"
          style={{ textAlign: 'center' }}
        >
          當您透過 Google、Facebook 註冊/登入，即代表您同意{' '}
          <NormalLink
            href="/terms"
            target="_blank"
            style={{ color: 'black', fontWeight: 700 }}
          >
            《網站服務條款》
          </NormalLink>
        </Typography>
        {thirdPartyResgisterError && errorTextField === 0 ? (
          <Typography
            variant="body2_loose"
            style={{ color: '#e20f0f', marginTop: 8, textAlign: 'center' }}
          >
            {thirdPartyAPIError ? (
              <>
                錯誤代碼 {thirdPartyAPIErrorCode} ，請聯絡透過{' '}
                <NormalLink
                  href="mailto:swap@yosgo.com"
                  target="_blank"
                  style={{ color: '#e20f0f', fontWeight: 700 }}
                >
                  swap@yosgo.com客服
                </NormalLink>
              </>
            ) : (
              '信箱已註冊 SWAP 會員，請直接登入'
            )}
          </Typography>
        ) : null}
        <div
          style={{
            height: 1,
            width: '100%',
            backgroundColor: theme.black.black500,
            margin: '24px 0',
          }}
        />
        <TextField
          error={errorTextField === 1 ? true : false}
          helperText={
            errorTextField === 1 ? (
              <Fade in={true} timeout={{ enter: 300, exit: 0 }}>
                <Box marginTop="4px">{errorMessages}</Box>
              </Fade>
            ) : null
          }
          fullWidth
          height={56}
          type="email"
          label={email ? '請輸入帳號(Email)' : ''}
          placeholder={!email ? '請輸入帳號(Email)' : ''}
          onChange={(e) => {
            setEmail(e.target.value)
          }}
          onKeyDown={(e) => {
            handleEnterLogin(e)
          }}
          InputProps={{
            startAdornment: (
              <MailOutlineIcon
                style={{
                  width: 20,
                  height: 20,
                  marginRight: 10,
                  color: theme.black.black700,
                }}
              />
            ),
          }}
          style={{ marginBottom: 12 }}
        />
        <TextField
          className={classes.textfield_password}
          error={errorTextField === 2 ? true : false}
          helperText={
            errorTextField === 2 ? (
              <Fade in={true} timeout={{ enter: 300, exit: 0 }}>
                <Box marginTop="4px">{errorMessages}</Box>
              </Fade>
            ) : null
          }
          fullWidth
          height={56}
          type={visible ? 'text' : 'password'}
          label={password ? '密碼(8個字元以上)' : ''}
          placeholder={!password ? '密碼(8個字元以上)' : ''}
          onChange={(e) => {
            setPassword(e.target.value)
          }}
          onKeyDown={(e) => {
            handleEnterLogin(e)
          }}
          InputProps={{
            startAdornment: (
              <LockOutlinedIcon
                style={{
                  width: 20,
                  height: 20,
                  marginRight: 10,
                  color: theme.black.black700,
                }}
              />
            ),
            endAdornment: (
              <IconButton
                onClick={() => setVisible(!visible)}
                disableRipple
                style={{ width: 24, height: 24 }}
              >
                {visible ? (
                  <Visibility
                    style={{
                      width: 24,
                      height: 24,
                      color: theme.black.black700,
                    }}
                  />
                ) : (
                  <VisibilityOff
                    style={{
                      width: 24,
                      height: 24,
                      color: theme.black.black700,
                    }}
                  />
                )}
              </IconButton>
            ),
          }}
          style={{ marginBottom: 12 }}
        />
        <TextField
          className={classes.textfield_password}
          error={errorTextField === 3 ? true : false}
          helperText={
            errorTextField === 3 ? (
              <Fade in={true} timeout={{ enter: 300, exit: 0 }}>
                <Box marginTop="4px">{errorMessages}</Box>
              </Fade>
            ) : null
          }
          fullWidth
          height={56}
          type={visible ? 'text' : 'password'}
          label={passwordConfirm ? '請再次輸入密碼' : ''}
          placeholder={!passwordConfirm ? '請再次輸入密碼' : ''}
          onChange={(e) => {
            setPasswordConfirm(e.target.value)
          }}
          onKeyDown={(e) => {
            handleEnterLogin(e)
          }}
          InputProps={{
            startAdornment: (
              <LockOutlinedIcon
                style={{
                  width: 20,
                  height: 20,
                  marginRight: 10,
                  color: theme.black.black700,
                }}
              />
            ),
            endAdornment: (
              <IconButton
                onClick={() => setVisible(!visible)}
                disableRipple
                style={{ width: 24, height: 24 }}
              >
                {visible ? (
                  <Visibility
                    style={{
                      width: 24,
                      height: 24,
                      color: theme.black.black700,
                    }}
                  />
                ) : (
                  <VisibilityOff
                    style={{
                      width: 24,
                      height: 24,
                      color: theme.black.black700,
                    }}
                  />
                )}
              </IconButton>
            ),
          }}
          style={{ marginBottom: 8 }}
        />
        <Box marginLeft="5px" marginBottom={'8px'}>
          <CheckBox
            onChange={() => {
              setAgreement(!agreement)
            }}
            label={
              <Typography variant="body2">
                完成註冊後，即表示您同意
                <Link href="/terms" target="_blank">
                  <b>《網站服務條款》</b>
                </Link>
              </Typography>
            }
          />
          {errorTextField === 4 ? (
            <Fade in={true} timeout={{ enter: 300, exit: 0 }}>
              <Typography variant="body2_loose" style={{ color: '#e20f0f' }}>
                請勾選上方同意條款以繼續註冊帳號
              </Typography>
            </Fade>
          ) : null}
        </Box>
      </div>
      <div style={{ width: '100%', height: 1, backgroundColor: '#dadada' }} />
      <div
        style={{
          padding: matchSM ? '24px 24px 24px 24px ' : '16px 16px 16px 16px',
        }}
      >
        <Button
          variant="primary"
          loading={loading}
          fullWidth
          onClick={handleRegister}
          style={{ marginBottom: 16 }}
        >
          註冊帳號
        </Button>
        <Typography variant="body1" style={{ textAlign: 'center' }}>
          已經有帳號？
          <Link
            onClick={() => setValue(0)}
            style={{
              cursor: 'pointer',
            }}
          >
            <b>點這裡登入</b>
          </Link>
        </Typography>
      </div>
    </Box>
  )
}

interface ResetPasswordProps {
  onClose: () => void
  setResetPassword: (resetPassword: boolean) => void
  setIsOpenSnackbar: (isOpenSnackbar: boolean) => void
  email: string
  setEmail: (email: string) => void
}
const ResetPassword = ({
  onClose,
  setResetPassword,
  setIsOpenSnackbar,
  email,
  setEmail,
}: ResetPasswordProps) => {
  const theme = useTheme()
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const handleRequestResetPassword = async () => {
    try {
      if (email.length === 0) {
        throw '請輸入先前註冊的Email，將寄發重置密碼的信件'
      }
      if (!handleEmailValidation(email)) {
        throw '請確認信箱格式是否正確'
      }
      setLoading(true)

      const requestResetPasswordResult: any = await RequestResetPassword({
        email: email,
        type: 'member',
      })
      setLoading(false)
      if (
        requestResetPasswordResult &&
        requestResetPasswordResult.errors === null
      ) {
        onClose()
        setIsOpenSnackbar(true)
        setResetPassword(false)
      } else if (
        requestResetPasswordResult &&
        requestResetPasswordResult.status === '404'
      ) {
        setErrorMessage('此信箱尚未註冊會員')
      }
    } catch (err: any) {
      setErrorMessage(err)
    }
  }
  const handleEnterLogin = (event: any) => {
    if (event.keyCode === 13) {
      handleRequestResetPassword()
    }
  }
  return (
    <Fade in={true}>
      <Box padding="24px">
        <TextField
          error={errorMessage.length !== 0}
          helperText={errorMessage}
          fullWidth
          height={56}
          type="email"
          label="請輸入帳號(註冊時的 Email)"
          autoFocus
          onChange={(e) => {
            setEmail(e.target.value)
          }}
          onKeyDown={(e) => {
            handleEnterLogin(e)
          }}
          InputProps={{
            startAdornment: (
              <MailOutlineIcon
                style={{
                  width: 20,
                  height: 20,
                  marginRight: 10,
                  color: theme.black.black700,
                }}
              />
            ),
          }}
          style={{ marginBottom: 16 }}
        />
        <Button
          variant="primary"
          loading={loading}
          fullWidth
          onClick={() => {
            handleRequestResetPassword()
          }}
        >
          重新設定密碼
        </Button>
      </Box>
    </Fade>
  )
}

interface GoogleLoginButtonProps {
  clienId: string
  state: string
  redirectUri: string
  isIOSMessage: boolean
}

const GoogleLoginButton = ({
  clienId,
  state,
  redirectUri,
  isIOSMessage,
}: GoogleLoginButtonProps) => {
  const styleProps: StyleProps = {
    isIOSMessage: isIOSMessage,
  }
  const classes = useStyles(styleProps)
  return (
    <div
      className={classes.googleButton}
      onClick={() =>
        isIOSMessage
          ? null
          : (window.location.href = `https://accounts.google.com/o/oauth2/auth?scope=email&client_id=${clienId}&redirect_uri=${redirectUri}&response_type=id_token&state=${state}`)
      }
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ position: 'absolute', left: 24 }}
      >
        <g clip-path="url(#clip0)">
          <path
            d="M19.805 10.2305C19.805 9.55068 19.7499 8.86724 19.6323 8.19849H10.2V12.0493H15.6014C15.3773 13.2913 14.6571 14.3899 13.6025 15.0881V17.5867H16.825C18.7173 15.845 19.805 13.2729 19.805 10.2305Z"
            fill="#4862CC"
          />
          <path
            d="M10.1999 20.0008C12.897 20.0008 15.1714 19.1152 16.8286 17.5867L13.6061 15.088C12.7096 15.698 11.5521 16.0434 10.2036 16.0434C7.59474 16.0434 5.38272 14.2833 4.58904 11.917H1.26367V14.4928C2.96127 17.8696 6.41892 20.0008 10.1999 20.0008Z"
            fill="#34A853"
          />
          <path
            d="M4.5854 11.917C4.16651 10.675 4.16651 9.3302 4.5854 8.08824V5.51245H1.2637C-0.154633 8.3381 -0.154633 11.6671 1.2637 14.4928L4.5854 11.917Z"
            fill="#FBBC04"
          />
          <path
            d="M10.1999 3.95805C11.6256 3.936 13.0035 4.47247 14.036 5.45722L16.8911 2.60218C15.0833 0.904587 12.6838 -0.0287217 10.1999 0.000673888C6.41892 0.000673888 2.96127 2.13185 1.26367 5.51234L4.58537 8.08813C5.37538 5.71811 7.59107 3.95805 10.1999 3.95805Z"
            fill="#EA4335"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
      使用 Google 帳號繼續
    </div>
  )
}

interface FacebookLoginButtonProps {
  state: string
  redirectUri: string
}
const FacebookLoginButton = ({
  state,
  redirectUri,
}: FacebookLoginButtonProps) => {
  const styleProps: StyleProps = {}
  const classes = useStyles(styleProps)
  return (
    <div
      className={classes.facebookButton}
      onClick={() =>
        (window.location.href = `https://www.facebook.com/v10.0/dialog/oauth?client_id=549090209290719&redirect_uri=${redirectUri}&response_type=token&scope=email&state=${state}`)
      }
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ position: 'absolute', left: 24 }}
      >
        <path
          d="M20 10C20 4.47715 15.5229 0 10 0C4.47715 0 0 4.47715 0 10C0 14.9912 3.65684 19.1283 8.4375 19.8785V12.8906H5.89844V10H8.4375V7.79688C8.4375 5.29063 9.93047 3.90625 12.2146 3.90625C13.3084 3.90625 14.4531 4.10156 14.4531 4.10156V6.5625H13.1922C11.95 6.5625 11.5625 7.3334 11.5625 8.125V10H14.3359L13.8926 12.8906H11.5625V19.8785C16.3432 19.1283 20 14.9912 20 10Z"
          fill="#4862CC"
        />
        <path
          d="M13.8926 12.8906L14.3359 10H11.5625V8.125C11.5625 7.33418 11.95 6.5625 13.1922 6.5625H14.4531V4.10156C14.4531 4.10156 13.3088 3.90625 12.2146 3.90625C9.93047 3.90625 8.4375 5.29063 8.4375 7.79688V10H5.89844V12.8906H8.4375V19.8785C9.47287 20.0405 10.5271 20.0405 11.5625 19.8785V12.8906H13.8926Z"
          fill="white"
        />
      </svg>
      使用 Facebook 帳號繼續
    </div>
  )
}

export default memo(AuthModal)

interface LoginModalProps {
  open: boolean
  onClose: () => void
  isFromPricing?: boolean
}
export const LoginModal = ({
  isFromPricing,
  open,
  onClose,
}: LoginModalProps) => {
  return (
    <AuthModal
      open={open}
      onClose={onClose}
      type="login"
      isFromPricing={isFromPricing}
    />
  )
}
interface RegisterModalProps {
  open: boolean
  onClose: () => void
}
export const RegisterModal = ({ open, onClose }: RegisterModalProps) => {
  return <AuthModal open={open} onClose={onClose} type="register" />
}
