import React, { ReactChild, useLayoutEffect, useState, memo } from 'react'

const LoadToShow = memo(
  ({ children, seconds = 0.5 }: { children: ReactChild; seconds?: number }) => {
    const [load, setLoad] = useState(false)

    useLayoutEffect(() => {
      setLoad(true)
    }, [])

    return (
      <div
        style={{
          opacity: load ? 1 : 0,
          transition: `opacity ${seconds}s`,
          willChange: 'opacity',
        }}
      >
        {children}
      </div>
    )
  }
)

export default LoadToShow
