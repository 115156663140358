import React, { useState } from "react";
import Image from "next/image";
import { motion } from "framer-motion";
interface LazyLoadImgProps {
  src: string;
  width?: number | string;
  height?: number | string;
  style?: React.CSSProperties;
  alt?: string;
  objectFit?:
    | "inherit"
    | "none"
    | "fill"
    | "-moz-initial"
    | "initial"
    | "revert"
    | "unset"
    | "contain"
    | "cover"
    | "scale-down"
    | undefined;
  layout?: "fill" | "fixed" | "intrinsic" | "responsive" | undefined;
  priority?: boolean | undefined;
  placeholder?: "blur" | "empty" | undefined;
  blurDataURL?: string | undefined;
  loading?: "lazy" | "eager" | undefined;
  noScale?: boolean | undefined;
  children?: JSX.Element;
}
const LazyLoadImg = ({
  src,
  width,
  height,
  style,
  alt,
  objectFit,
  layout,
  priority,
  placeholder,
  blurDataURL,
  loading,
  noScale,
  children,
}: LazyLoadImgProps) => {
  const [loaded, setLoaded] = useState(false);
  const variants = {
    loading: {
      scale: noScale ? 1 : 1,
      filter: "blur(10px)",
    },
    loaded: {
      scale: 1,
      filter: "blur(0px)",
    },
  };
  return (
    <motion.div
      style={{ ...style, originX: 0.5, originY: 0.5 }}
      variants={variants}
      initial="loading"
      animate={loaded ? "loaded" : "loading"}
      transition={{ duration: 0.8 }}
    >
      <Image
        onLoadingComplete={() => {
          setLoaded(true);
        }}
        src={src} // url
        width={width}
        height={height}
        objectFit={objectFit}
        layout={layout}
        alt={alt}
        priority={priority}
        placeholder={placeholder}
        blurDataURL={blurDataURL} // 模糊圖url
        unoptimized={true} // 不使用next image再壓縮
        loading={loading}
      />
      {loaded ? children : null}
    </motion.div>
  );
};

export default LazyLoadImg;
